<form [formGroup]="this.resetPwFg" (ngSubmit)="changePW()">
    <mat-form-field appearance="outline">
        <mat-label for="password">
            {{ 'New Password' | translate }}
        </mat-label>
        <input matInput formControlName="password"
            [type]="hidePW ? 'password' : 'text'"
        >
        <mat-icon matSuffix (click)="hidePW = !hidePW">
            {{hidePW ? 'visibility_off' : 'visibility'}}
        </mat-icon>
        <mat-error>
            {{passwordNoteStr}}
        </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label for="passwordRepeat">
            {{ 'Repeat new Password' | translate }}
        </mat-label>
        <input matInput type="password" formControlName="passwordRepeat">
    </mat-form-field>

    <mat-error *ngIf="resetPwFg.getError('passwordsDontMatch')" translate>
        passwordsDontMatch
    </mat-error>
    <div class="filler"></div>
    <div class="form-row">
        <button mat-flat-button color="accent" [disabled]="resetPwFg.invalid" type="submit">
            {{ 'Change Password' | translate}}
        </button>
    </div>
</form>