import { Component, Input } from "@angular/core";
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ResetPasswordUseCase } from "src/app/core/useCases/credentials.usecases";
import { maxPasswordLength, minPasswordLength } from 'src/global';
import { NotificationService } from "../../services/notification.service";

const passwordMatchValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => { 
    const password = control.get('password');
    const passwordRepeat = control.get('passwordRepeat');

    return password?.value !== passwordRepeat?.value ? { passwordsDontMatch: true } : null;
};

@Component({
    selector: 'app-password-reset',
    templateUrl: './pw-reset.component.html',
    styleUrls: ['./pw-reset.component.scss']
})
export class PasswordResetComponent {
    @Input() keyParam!: string;
    resetPwFg: FormGroup;
    hidePW = true;
    passwordNoteStr: string = "Chracter Minimum: " + minPasswordLength + " Character Maximum: " + maxPasswordLength

    constructor(
        private resetPwUc: ResetPasswordUseCase,
        private router: Router,
        private notificationService: NotificationService
    ) {

        this.resetPwFg = new FormGroup({
            'password': new FormControl('', 
                [Validators.required, 
                Validators.minLength(minPasswordLength),
                Validators.maxLength(maxPasswordLength)]
            ),
            'passwordRepeat': new FormControl('', Validators.required)
        }, { validators: [passwordMatchValidator] });
        this.resetPwFg.get
    };

    changePW() {
        this.resetPwUc.execute({
            password: this.resetPwFg.get('password')?.value,
            passwordRepeat: this.resetPwFg.get('passwordRepeat')?.value,
            key: this.keyParam,
        }
        ).subscribe({
            next: () => {
                this.notificationService.notify("Password changed succesfully");
                this.router.navigateByUrl('logout');
            },
            error: err => { this.notificationService.notifyError("An error occured, please try again.") },
        });
    }

}
