
<form [formGroup]="requestNewPwForm" (ngSubmit)="submitForm()" class="mat-typography">
    <p>{{'Request password reset' | translate}}</p>

    <mat-form-field appearance="outline">
        <mat-label for="email">
            {{ 'Your Email' | translate }}
        </mat-label>
        <input matInput id="email" type="email" class="validate"
            formControlName="email">
        <mat-error *ngIf="requestNewPwForm.getError('required', 'email')" translate>required</mat-error>
        <mat-error *ngIf="requestNewPwForm.getError('email', 'email')" translate>invalidEmail</mat-error>
    </mat-form-field>
    <div class="filler"></div>

    <div class="row">
        <button [disabled]="requestNewPwForm.invalid" mat-flat-button color="accent" type="submit">
            {{'Send' | translate}}
        </button>
    </div>


</form>
